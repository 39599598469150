// import { Link as AniLink fade } from "gatsby";
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { FaCode, FaFile, FaInfoCircle, FaStar } from 'react-icons/fa';
import '../sass/main.scss';
import { FiSun, FiMoon } from 'react-icons/fi';


const Header = ({ siteTitle }) => {
  const [darkMode, setDarkMode] = useState("");
  useEffect(() => {
    setDarkMode(localStorage.getItem("darkMode") || "")
  }, [])

  useEffect(() => {
    if (darkMode) {
      document.body.classList.add("dark")
    }
    else {
      document.body.classList.remove("dark")
    }
  }, [darkMode])

  const toggleDarkMode = () => {
    if (darkMode === "dark") {
      localStorage.setItem("darkMode", "")
      setDarkMode("");
    } else {
      localStorage.setItem("darkMode", "dark")
      setDarkMode("dark")
    }

  }

  return <header className="header">
    <nav>
      <NavLink to="/" text="About" Icon={FaInfoCircle}></NavLink>
      <NavLink to="/resume" text="Resume" Icon={FaFile}></NavLink>
      <NavLink to="/projects" text="Projects" Icon={FaCode}></NavLink>
      <NavLink to="/favs" text="Favourites" Icon={FaStar}></NavLink>
      <DarkModeButton darkMode={darkMode} setDarkMode={toggleDarkMode} />
    </nav>
  </header>
};

const NavLink = ({ text, Icon, to }) => {
  return (
    <span className="nav-link">
      {/* <AniLink fade duration={0.1} activeClassName="active" to={to}> */}
      <Link to={to} activeClassName="active">
        <span className="desktop">{text}</span>
        <span className="mobile"><Icon /></span>
      </Link>
      {/* </AniLink> */}
    </span>
  )
}

const DarkModeButton = ({ darkMode, setDarkMode }) => {
  return <span className="dark-mode-button" onClick={setDarkMode}>
    {darkMode ? <FiMoon /> : <FiSun />}
  </span>
}

Header.propTypes = {
  siteTitle: PropTypes.string,
};

Header.defaultProps = {
  siteTitle: '',
};

export default Header;
