import PropTypes from "prop-types"
import React from "react"
import { FaComment, FaHeart, FaRegCopyright } from "react-icons/fa"
import { FiMessageCircle } from "react-icons/fi"
import "../sass/main.scss"
import { Link } from "gatsby"

const Footer = ({ siteTitle }) => (
  <footer className="footer grid-x">
    <div className="info cell medium-3 small-12 grid-x">
      Built with ❤ using&nbsp;<a href="https://wwww.gatsby.org">Gatsby.js</a>
    </div>
    <div className="copyright cell medium-6 small-12">
      <FaRegCopyright />
      <span>
        Joshua Nazareth,&nbsp;
        {new Date().getFullYear()}.
      </span>
    </div>
    <Link to="/contact">
      <span data-tooltip class="top" tabindex="2" title="Let's talk!">
        <div className="action-button contact-button">
          <FiMessageCircle />
        </div>
      </span>
    </Link>
  </footer>
)

Footer.propTypes = {
  siteTitle: PropTypes.string,
}

Footer.defaultProps = {
  siteTitle: "",
}

export default Footer
